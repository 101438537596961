import React from "react";

import Layout from "../components/layout";
import Seo from "../components/seo";
import "../scss/main.scss";
import "../scss/statuts.scss";

const StatutsPage = () => {
	return (
		<Layout>
			<Seo title="Nos statuts" />
			<div className="page-content page-statuts">
				<section className="page-section status-section">
					<h3 className="section-title">Les status de l'association</h3>
					<h4 className="status-title">Constitution de l'association</h4>
					<p className="status-paragraph">
						Il est créé une association dénommée « CLI ». Elle est inscrite au
						Registre des Associations du Tribunal Judiciaire de Strasbourg, et
						est régie par les articles 21 à 79 du Code Civil local, maintenus en
						vigueur par la loi d’introduction de la législation civile française
						du 1 er juin 1924. La publication apparaîtra dans les Dernières
						Nouvelles d'Alsace.
					</p>
					<p className="status-paragraph">
						Les présents statuts font références à des personnes et à leurs
						différents rôles. Par défaut, ces rôles et les accords sont au
						féminin.
					</p>

					<h4 className="status-title">Objet</h4>
					<p className="status-paragraph">
						L’objet social de l'association est de développer une production
						numérique solidaire, émancipée et émancipatrice, telle que définie
						par les concernées dans l'article « Orientation » de la charte,
						régulièrement amendée par l'assemblée générale.
					</p>
					<p className="status-paragraph">
						Pour ce faire, l'association vise à devenir une coopérative
						d'activité et d'emploi et s'autorise toutes opérations commerciales,
						financières ou juridiques se rattachant à l’objet indiqué ci-dessus
						et visant à favoriser son activité dans un but non-lucratif.
					</p>

					<h4 className="status-title">Siège social</h4>
					<p className="status-paragraph">
						Le siège social de l'association est établi au *** 67000 STRASBOURG.
						Il pourra être transféré en un autre lieu sur décision des membres
						réunies en assemblée générale.
					</p>

					<h4 className="status-title">Durée</h4>
					<p className="status-paragraph">
						L'association est créée pour une durée illimitée, ou jusqu’à ce
						qu’une procédure de dissolution à l’unanimité des membres réunies en
						assemblée générale soit prononcée.
					</p>

					<h4 className="status-title">Procédue d'adhésion des membres</h4>
					<p className="status-paragraph">
						Peut devenir membre toute personne physique en ayant fait la demande
						écrite, précisant qu’elle est d’accord avec la charte et les statuts
						de l’association, par papier ou par voie électronique, auprès de
						l’assemblée générale et après l’acceptation de cette dernière. La
						nouvelle adhérente devra indiquer dans sa demande le mode de
						communication à employer, par exemple son adresse e-mail ou son
						adresse postale. Il n’y a aucune contribution financière devant être
						fournie afin d’acquérir la qualité de membre.
					</p>

					<h4 className="status-title">Perte de la qualité de membre</h4>
					<p className="status-paragraph">
						Renonce à sa qualité de membre toute membre qui :
					</p>
					<ul className="status-list">
						<li>démissione de son statut de membre</li>
						<li>
							fait l'objet d'une exclusion pour le non-respect de la charte
						</li>
						<li>décède</li>
					</ul>
					<p className="status-paragraph">
						En dehors de ces cas, l'AG ne peut exclure directement une membre
						qu'en la suspendant lors d'une AG, et après avoir confirmé son
						exclusion lors de l'assemblée suivante.
					</p>

					<h4 className="status-title">Mode d'administration</h4>
					<p className="status-paragraph">
						L’association fonctionne selon un mode d’administration dit
						'autogéré'. Les membres sont par conséquent collectivement
						responsables du bon fonctionnement de l’association et ont pour
						charge de s’impliquer dans la mesure de leurs disponibilités et
						compétences.
					</p>
					<p className="status-paragraph">
						<span className="bold">L’Assemblée Générale (AG)</span> regroupe
						l’ensemble des membres et est souveraine sur l'ensemble des
						décisions liées à l'association, à l'exception des décisions
						d'exclusion des membres, conformément à l'article 6 des présents
						statuts.
					</p>
					<p className="status-paragraph">
						<span className="bold">Le Collège Solidaire (CS)</span> a pour
						responsabilité d’administrer l’association et de la représenter dans
						les actes de la vie civile. Il n’exerce aucun pouvoir décisionnaire
						au sein de l’association. Il représente légalement l’association
						devant la justice. Sont membres du CS l’ensemble des membres
						dépositaires d’un mandat.
					</p>

					<h4 className="status-title">Assemblée Générale</h4>
					<p className="status-paragraph">
						L’AG se rassemble sur décision, par ordre d’importance :
					</p>
					<ul className="status-list">
						<li>d'une précédente AG,</li>
						<li>de la personne mandatée à cet effet,</li>
						<li>ou a défaut par une membre du CS.</li>
					</ul>
					<p className="status-paragraph">
						Les convocations se feront au moins une semaine à l’avance par voies
						écrites papier ou numérique et mentionneront un ordre du jour
						proposé, préalablement choisi par l’ensemble des membres de
						l’association, une date et un lieu de convocation.
					</p>
					<p className="status-paragraph">
						La première décision de l'AG consiste à décider de ses modalités de
						tenue. Les modalités de tenue des AG seront consignées dans un
						compte-rendu. Par exemple, peuvent-être créés un mandat destiné à
						répartir le temps de parole, et un mandat destiné à rédiger le
						compte-rendu.
					</p>
					<p className="status-paragraph">
						Lors des AG, les délibérations et les résolutions doivent être
						consignées dans un procès-verbal qui est approuvé par les membres
						présentes et par les représentantes des membres absentes.
					</p>
					<p className="status-paragraph">
						Les modalités d'approbation sont définies dans la charte.
					</p>

					<h4 className="status-title">Quorum et majorité</h4>
					<p className="status-paragraph">
						Pour que l’assemblée puisse délibérer valablement, plus de la moitié
						des membres doivent être présentes ou représentées.
					</p>
					<p className="status-paragraph">
						Les décisions par consensus sont privilégiées, cependant des
						décisions peuvent être prises à la majorité absolue des membres
						présentes, si aucun consensus n’est trouvé.
					</p>

					<h4 className="status-title">Mandats</h4>
					<p className="status-paragraph">
						L’AG peut émettre des mandats pour faire appliquer des décisions
						prises par celle-ci. Les mandats émis par l'AG sont impératifs (ils
						ne s'appliquent que pour la tâche explicitement définie) et
						révocables (par l'AG ou tout autre mandat le permettant
						explicitement). Le mandat expire de facto lorsque la tâche est
						exécutée ou quand la durée du mandat fixée par l'assemblée générale
						est atteinte.
					</p>

					<h4 className="status-title">
						Tenue des comptes et information des membres
					</h4>
					<p className="status-paragraph">
						Les personnes dépositaires d’un mandat de trésorerie doivent tenir
						une comptabilité conforme aux lois en vigueur. Il doit établir le
						bilan, le compte de résultats, les annexes et le rapport de gestion
						dans le mois qui suit la clôture de chaque exercice.
					</p>
					<p className="status-paragraph">
						Ces documents ainsi que le rapport de gestion devront être
						consultables par l'ensemble des membres. L'exercice comptable
						prendra comme repère la date anniversaire de l'association ; cette
						date sera précisée dans la charte.
					</p>

					<h4 className="status-title">
						Rémunération et défraiements des membres
					</h4>
					<p className="status-paragraph">
						L’AG vote le montant de la rémunération des membres en contrepartie
						de l'exercice de leurs fonctions de dirigeantes.
					</p>
					<p className="status-paragraph">
						L’AG a l'obligation de revoter ce montant au minimum une fois par
						an.
					</p>
					<p className="status-paragraph">
						L’AG peut également prévoir le versement d'une somme forfaitaire
						mensuelle aux dirigeantes, calculée en fonction de leurs frais fixes
						liés à l'exercice de leurs fonctions.
					</p>
					<p className="status-paragraph">
						L’AG se garde le droit de répartir équitablement entre les membres,
						les bénéfices de l'exercice comptable, dans le respect des lois en
						vigueurs.
					</p>

					<h4 className="status-title">Dissolution</h4>
					<p className="status-paragraph">
						La présente association pourra être dissoute par anticipation dans
						l’un des cas suivants :
					</p>
					<ul className="status-list">
						<li>décision collective des membres</li>
						<li>décision de justice</li>
					</ul>

					<h4 className="status-title">Liquidation</h4>
					<p className="status-paragraph">
						En cas de dissolution, l'association est placée d’office en
						liquidation. Dans ce cas, sa dénomination sociale doit être suivie
						des mots « association en liquidation » sur tous les documents
						destinés aux tiers.{" "}
					</p>
					<p className="status-paragraph">
						Un comité de liquidation est désigné et ses pouvoirs sont fixés lors
						de l’assemblée qui décide la dissolution.
					</p>
					<p className="status-paragraph">
						Les bénéficiaires de l'ensemble des possessions (trésorerie, compte
						bancaire, biens mobiliers et immobiliers) de l'association dissoute
						peuvent-être des organisations ou des personnes physiques, précisées
						dans la charte.
					</p>

					<h4 className="status-title">Contestations</h4>
					<p className="status-paragraph">
						En cas de litige entre les membres de l'association, une
						conciliation devra avoir lieu. La personne médiatrice sera choisie
						au consensus par les parties concernées.
					</p>
					<p className="status-paragraph">
						Si aucune personne médiatrice n'est désignée au bout de trois
						tentatives consignées, ou si la conciliation ne permet pas de régler
						le litige, celui-ci relèvera en dernier recours du Tribunal
						d’Instance dont dépend le siège social.
					</p>
					<h4 className="status-title">Modification des statuts</h4>
					<p className="status-paragraph">
						La modification des présents statuts ne peut être effectuée que sur
						la décision de l’ AG, par le vote d’au moins trois quarts des
						membres présentes, tel que le prévoit le Code Civil local (art. 33),
						et doit être confirmée lors de l'assemblée générale suivante. À
						défaut et en cas de refus, la modification est annulée.
					</p>
				</section>
			</div>
		</Layout>
	);
};
export default StatutsPage;
